<template>
  <div>
    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      
      <el-table-column type="selection"></el-table-column>
      <el-table-column label="macid" prop="macid"></el-table-column>
      <el-table-column label="站点名称" prop="station.stationName"></el-table-column>
      <el-table-column label="仓门" prop="cabinet.name"></el-table-column>
      <el-table-column label="代理商" prop="sendOperation.operationName"></el-table-column>
      <el-table-column label="入库时间" prop="createAt"></el-table-column>
      <el-table-column label="入库类型" prop="outTime">
        <template #default="scope">
          <span>
            {{scope.row.type === 1 ? scope.row.inAdminInfo.nickName : "用户换电"}}
          </span>
        </template>
      </el-table-column>

      <el-table-column label="状态" prop="status">
        <template #default="scope">
          {{ scope.row.status === 1 ? "入库" : "已出库" }}
        </template>
      </el-table-column>

      <el-table-column label="出库时间" prop="outTime"></el-table-column>
      <el-table-column label="出库类型" prop="outTime">
        <template #default="scope">
          <span v-if="scope.row.deviceUserId">用户借电</span>
          <span v-else>
            {{ scope.row.outAdminInfo.nickName }}
          </span>
        </template>
      </el-table-column>


    </el-table>

    <el-pagination
      v-if="pageable"
      layout="total,prev, pager, next,jumper"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>
  </div>
</template>

<script>
import { toRefs, toRef, reactive } from "vue";
import { validatenull } from "@/common/validate";
import { fetchStationBatteryList } from "@/api/station";

export default {
  props: ["listQuery", "pageable"],

  setup(props, context) {
    let listQuery = toRef(props, "listQuery");

    const state = reactive({
      total: 0,
      dataSource: [],
      modalData: {},
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      listQuery: listQuery,
    });

    const getList = (current) => {
      if (!validatenull(current)) {
        state.pages.pageIndex = current;
      }

      fetchStationBatteryList({
        query: state.listQuery,
        ...state.pages,
      }).then((res)=>{
        let list = res.data.data.records || [];
          state.total = res.data.data.total;
          state.dataSource = list;
      })
    };

    return {
      ...toRefs(state),
      listQuery: listQuery.value,
      getList,
    };
  },
};
</script>